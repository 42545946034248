<script lang="ts">
import Vue, { PropType } from "vue";

export enum TransitionStyle {
  NONE = "none",
  /** Fades in and fades out. Does not support cross-fading. */
  FADE = "fade",
  /** Pops in and shrinks out. Does not support overlapping elements. */
  POP = "pop",
  /** Pops in and disappears out. Intended for overlapping elements. */
  POP_REPLACE = "pop_replace",
  SLIDE_ENTER_FORWARD = "slide_enter_forward",
  SLIDE_EXIT_BACKWARD = "slide_exit_backward",
  SLIDE_IN = "slide_in",
  /** Slides up from the bottom for enter. Slides down for exit. */
  SLIDE_UP_DOWN = "slide_up_down",
  FADE_DOWN = "fade_down",
}

export default Vue.extend({
  inheritAttrs: false,
  props: {
    transitionStyle: {
      type: String as PropType<TransitionStyle | string>,
      required: true,
    },
    asGroup: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    transitionName(): string {
      switch (this.transitionStyle) {
        case TransitionStyle.NONE:
          return "";
        case TransitionStyle.FADE:
          return "canary-fade-transition";
        case TransitionStyle.POP:
          return "canary-pop-transition";
        case TransitionStyle.POP_REPLACE:
          return "canary-pop-replace-transition";
        case TransitionStyle.SLIDE_ENTER_FORWARD:
          return "canary-slide-enter-forward-transition";
        case TransitionStyle.SLIDE_EXIT_BACKWARD:
          return "canary-slide-exit-backward-transition";
        case TransitionStyle.SLIDE_IN:
          return "canary-slide-in-transition";
        case TransitionStyle.SLIDE_UP_DOWN:
          return "canary-slide-up-down-transition";
        case TransitionStyle.FADE_DOWN:
          return "canary-fade-down-transition";
        default:
          // Fallback to using the name directly to support custom transitions.
          return this.transitionStyle;
      }
    },
  },
});
</script>

<template>
  <transition
    v-if="!asGroup"
    :name="transitionName"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </transition>
  <transition-group
    v-else
    :name="transitionName"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </transition-group>
</template>

<style lang="scss">
@import "shared/styles/base/variables.scss";

.canary-pop-transition {
  &-enter-active {
    transition: transform $transition-duration-default
      cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  &-leave-active {
    transition: transform $transition-duration-default ease-out;
  }
  &-enter,
  &-leave-to {
    transform: scale(0);
  }
}

.canary-pop-replace-transition {
  &-enter-active {
    transition: transform $transition-duration-default
      cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  &-enter {
    transform: scale(0);
  }
  &-leave {
    display: none;
  }
}

.canary-slide-enter-forward-transition {
  &-enter-active,
  &-leave-active {
    transition:
      transform $transition-duration-default ease-out,
      opacity $transition-duration-default ease-out;
  }
  &-enter {
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateX(10%);
    z-index: 1;
    [dir="rtl"] & {
      transform: translateX(-10%);
    }
  }
  &-enter-to {
    position: relative;
  }
  &-leave-to {
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateX(-10%);
    z-index: 0;
    [dir="rtl"] & {
      transform: translateX(10%);
    }
  }
}

.canary-slide-exit-backward-transition {
  &-enter-active,
  &-leave-active {
    transition:
      transform $transition-duration-default ease-out,
      opacity $transition-duration-default ease-out;
  }
  &-enter {
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateX(-10%);
    z-index: 0;
    [dir="rtl"] & {
      transform: translateX(10%);
    }
  }
  &-enter-to {
    position: relative;
  }
  &-leave-to {
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateX(10%);
    z-index: 1;
    [dir="rtl"] & {
      transform: translateX(-10%);
    }
  }
}

.canary-fade-transition {
  &-enter-active,
  &-leave-active {
    transition: opacity $transition-duration-default ease-out;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.canary-slide-in-transition {
  &-enter-active,
  &-leave-active {
    position: absolute;
    top: 0;
    transition: transform $transition-duration-default ease-out;
    width: 100%; // ensure it takes full width while transitioning
  }

  &-enter,
  &-leave-to {
    transform: translateX(100%);

    [dir="rtl"] & {
      transform: translateX(-100%);
    }
  }

  &-enter-to,
  &-leave {
    transform: translateX(0);
  }
}

.canary-fade-down-transition {
  &-enter-active,
  &-leave-active {
    position: absolute;
    top: 0;
    transition: all $transition-duration-default ease-out;
    width: 100%; // ensure it takes full width while transitioning
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-8px);
  }

  &-enter-to,
  &-leave {
    transform: translateY(0);
  }
}

.canary-slide-up-down-transition {
  &-enter-active,
  &-leave-active {
    position: absolute;
    top: 0;
    transition: all $transition-duration-default ease-in-out;
    transition-delay: 50ms;
    width: 100%; // ensure it takes full width while transitioning
  }

  &-enter,
  &-leave-to {
    transform: translateY(100%) scale(0.75);
    opacity: 0;
  }

  &-enter-to,
  &-leave {
    transform: translateY(0);
  }
}
</style>
