<script lang="ts">
import { defineComponent, PropType } from "vue";

import { useTheme } from "../ThemeStore";
import { ToastType } from "./ToastType";

const ERROR_BACKGROUND = "#E40046";
const ERROR_FONT_COLOR = "#FFF";

export default defineComponent({
  name: "CanaryToast",
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<ToastType>,
      default: ToastType.DEFAULT,
    },
  },
  setup() {
    return {
      theme: useTheme(),
    };
  },
  computed: {
    style() {
      switch (this.type) {
        case ToastType.DEFAULT:
          return {
            background: this.theme.backgroundColorButton,
            color: this.theme.fontColorButton,
          };
        case ToastType.ERROR:
          return {
            background: ERROR_BACKGROUND,
            color: ERROR_FONT_COLOR,
          };
        default:
          return {};
      }
    },
  },
});
</script>

<template>
  <div role="alert" :class="$style.toast" :style="style">
    {{ text }}
  </div>
</template>

<style lang="scss" module>
@import "shared/styles/base/colors.scss";
@import "shared/styles/base/typography.scss";
@import "shared/styles/base/variables.scss";

.toast {
  @include font-style($body-sm, 500);
  border-radius: $border-radius-default;
  box-shadow: $box-shadow-default;
  line-height: 22px;
  max-width: calc(100vw - 16px);
  padding: 5px 16px;
}
</style>
