export function isDark(color: string): boolean {
  // NOTE: 128 is a constant value used by many frameworks.
  return getLuma(color) < 128;
}

export function getLuma(color: string): number {
  const rgb = parseInt(color.replace("#", ""), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  // Using ITU-R BT.709.
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}
