<script lang="ts">
import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import CanaryToast from "./CanaryToast.vue";
import ToastStore, {
  ToastParams,
  ToastPosition,
  ToastTransition,
  VueComponent,
} from "./ToastStore";

const DURATION = 5000;
const DEFAULT_POSITION = ToastPosition.BOTTOM;
const DEFAULT_OFFSET = 16;

enum Transition {
  DEFAULT = "themed-toast-container-default-transition",
  POP = "themed-toast-container-pop-transition",
}

export default Vue.extend({
  name: "CanaryToastContainer",
  components: { CanaryToast },
  data(): {
    activeToast: ToastParams | null;
    ToastPosition: typeof ToastPosition;
  } {
    return { activeToast: null, ToastPosition };
  },
  computed: {
    toastStore(): ToastStore {
      return getModule(ToastStore, this.$store);
    },
    queuedToast(): ToastParams | null {
      return this.toastStore.active;
    },
    component(): VueComponent {
      if (!this.activeToast) return {};
      return "component" in this.activeToast
        ? this.activeToast.component
        : CanaryToast;
    },
    componentProps(): Record<string, unknown> {
      if (!this.activeToast) return {};
      return "component" in this.activeToast
        ? this.activeToast.props || {}
        : {
            text: this.activeToast.text,
            type: this.activeToast?.type,
          };
    },
    componentEvents(): Record<string, unknown> {
      if (!this.activeToast) return {};
      return "component" in this.activeToast
        ? this.activeToast.events || {}
        : {};
    },
    style(): Partial<CSSStyleDeclaration> {
      const offset = this.activeToast?.offset ?? DEFAULT_OFFSET;
      switch (this.activeToast?.position || DEFAULT_POSITION) {
        case ToastPosition.TOP:
          return {
            top: `${offset}px`,
          };
        case ToastPosition.BOTTOM:
          return {
            bottom: `${offset}px`,
          };
        default:
          return {};
      }
    },
    transitionName(): string {
      switch (this.activeToast?.transition) {
        case ToastTransition.POP:
          return Transition.POP;
        default:
          return Transition.DEFAULT;
      }
    },
  },
  watch: {
    queuedToast(toast: ToastParams) {
      if (toast && !this.activeToast) {
        this.displayToast(toast);
      }
      if (!toast && this.activeToast) {
        this.activeToast = null;
      }
    },
  },
  methods: {
    displayToast(toast: ToastParams) {
      this.activeToast = toast;
      setTimeout(() => {
        this.activeToast = null;
      }, DURATION);
    },
    onAfterLeave() {
      this.toastStore.clearActive();
    },
  },
});
</script>

<template>
  <div :class="$style.toastContainer">
    <transition :name="transitionName" @after-leave="onAfterLeave">
      <component
        :is="component"
        v-bind="componentProps"
        v-if="activeToast"
        :class="$style.toast"
        :style="style"
        v-on="componentEvents"
      />
    </transition>
  </div>
</template>

<style lang="scss" module>
@import "shared/styles/base/colors.scss";
@import "../zindex.scss";

.toastContainer {
  display: flex;
  justify-content: center;
}

.toast {
  position: fixed;
  z-index: $z-index-toast;
}
</style>

<style lang="scss">
@import "shared/styles/base/variables.scss";
.themed-toast-container-default-transition {
  &-enter-active {
    transition:
      transform $transition-duration-default ease-out,
      opacity $transition-duration-default linear;
  }
  &-leave-active {
    transition:
      transform $transition-duration-default ease-in,
      opacity $transition-duration-default linear;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.themed-toast-container-pop-transition {
  &-enter-active {
    transition:
      transform $transition-duration-default cubic-bezier(0.34, 1.56, 0.64, 1),
      opacity $transition-duration-default;
  }
  &-leave-active {
    transition:
      transform $transition-duration-default ease-out,
      opacity $transition-duration-default;
  }
  &-enter,
  &-leave-to {
    transform: scale(0.5);
    opacity: 0;
  }
}
</style>
