<script lang="ts">
import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

import { isDark } from "shared/helpers/colors";

import { useTheme } from "../ThemeStore";
import ModalStore, { ModalAnimation, VisibleModal } from "./ModalStore";

export default defineComponent({
  name: "CanaryModalContainer",
  setup() {
    const theme = useTheme();
    return {
      theme,
    };
  },
  data(): {
    isContainerVisible: boolean;
  } {
    return {
      isContainerVisible: false,
    };
  },
  computed: {
    modalStore(): ModalStore {
      return getModule(ModalStore, this.$store);
    },
    visibleModals(): VisibleModal[] {
      return this.modalStore.visibleModals;
    },
    activeModal(): VisibleModal | null {
      return this.modalStore.activeModal;
    },
    closingModal(): VisibleModal | null {
      return this.modalStore.closingModal;
    },
    hasLightBackground() {
      return isDark(this.theme.backgroundColorPage);
    },
    activeAnimation() {
      const animation = (() => {
        if (
          (this.closingModal &&
            this.closingModal?.params.animation === ModalAnimation.SLIDE) ||
          (!this.closingModal &&
            this.activeModal?.params.animation === ModalAnimation.SLIDE)
        ) {
          return "themed-modal-container-modal-slide-in-transition";
        }

        if (this.modalStore.visibleModals.length == 0) {
          return "themed-modal-container-modal-zoom-out-transition";
        }

        if (this.modalStore.visibleModals.length == 1) {
          return "themed-modal-container-modal-zoom-in-slide-out-transition";
        }

        return "themed-modal-container-modal-slide-in-out-transition";
      })();

      return animation;
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    onKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape" && this.activeModal?.params.closeOnEscape) {
        this.modalStore.close({ modalId: this.activeModal.id });
      }
    },
    onBeforeEnter() {
      this.isContainerVisible = true;
    },
    onAfterEnter() {
      this.modalStore.updateProps({
        modalId: this.activeModal?.id,
        updates: { isModalReady: true },
      });
    },
    onBeforeLeave() {
      this.modalStore.updateProps({
        modalId: this.activeModal?.id,
        updates: { isModalReady: false },
      });
    },
    onAfterLeave() {
      this.isContainerVisible = this.modalStore.hasActiveModal;
      this.modalStore.finishClose();
    },
    onClose() {
      this.modalStore.close();
    },
    onClickComponent(evt: MouseEvent) {
      /***
       * Prevent the click from bubbling to the background
       * so we know a click is within the modal and allow for
       * background clicks to close the modal
       */
      evt.stopPropagation();
    },
    onBackgroundClick() {
      if (this.activeModal?.params.closeOnBackgroundClick === true) {
        this.modalStore.close({
          modalId: this.activeModal.id,
        });
      }
    },
  },
});
</script>

<template>
  <div>
    <transition name="themed-modal-container-background-transition">
      <div
        v-if="visibleModals.length"
        :class="[
          $style.background,
          { [$style['background--light']]: hasLightBackground },
        ]"
      ></div>
    </transition>

    <transition-group
      :name="activeAnimation"
      @before-enter="onBeforeEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @after-leave="onAfterLeave"
    >
      <div
        v-for="modal in visibleModals"
        :key="modal.id"
        :class="[
          $style.container,
          {
            [$style['container--underneath']]:
              activeModal && modal.id != activeModal.id,
          },
        ]"
        @click="onBackgroundClick"
      >
        <component
          :is="modal.params.component"
          v-bind="modal.params.props || {}"
          :class="[
            $style.modal,
            {
              [$style['modal--underneath']]:
                activeModal && modal.id != activeModal.id,
            },
          ]"
          v-on="modal.params.events || {}"
          @close="onClose"
          @click.native="onClickComponent"
        />
      </div>
    </transition-group>
  </div>
</template>

<style lang="scss" module>
@import "shared/styles/base/colors.scss";
@import "../zindex.scss";

.container,
.background {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-modal;
}

.background {
  background: rgba(0, 0, 0, 0.5);
  &--light {
    background: rgba(255, 255, 255, 0.5);
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  z-index: $z-index-modal;
  & > * {
    transition: transform 300ms ease-out;
  }
  &--underneath {
    & > * {
      transform: scale(0.95);
    }
  }
}

.modal {
  &::after {
    content: "";
    transition: opacity 300ms linear;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  &--underneath {
    position: relative;
    &::after {
      opacity: 1;
      z-index: 2;
      background: rgba(0, 0, 0, 0.25);
    }
  }
}

.underneathCover {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.html--clip {
  overflow: hidden !important;
}
</style>

<style lang="scss">
.themed-modal-container-background-transition {
  &-enter-active,
  &-leave-active {
    transition: opacity 300ms linear;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.themed-modal-container-modal-zoom-out-transition {
  &-leave-active {
    transition:
      transform 300ms ease-in,
      opacity 300ms linear;
  }
  &-leave-to {
    opacity: 0;
    transform: scale(0.4);
  }
}

.themed-modal-container-modal-zoom-in-slide-out-transition {
  &-enter-active {
    transition:
      transform 300ms ease-out,
      opacity 300ms linear;
  }
  &-leave-active {
    transition: transform 300ms ease-in;
  }
  &-enter {
    opacity: 0;
    transform: scale(0.4);
  }
  &-leave-to {
    transform: translateY(100%);
  }
}

.themed-modal-container-modal-slide-in-out-transition {
  &-enter-active {
    transition: transform 300ms ease-out;
  }
  &-leave-active {
    transition: transform 300ms ease-in;
  }

  &-enter,
  &-leave-to {
    transform: translateY(100%);
  }
}

.themed-modal-container-modal-slide-in-transition {
  &-enter-active,
  &-leave-active {
    position: absolute;
    top: 0;
    transition: transform 300ms ease-in-out;
    width: 100%;
  }

  &-enter,
  &-leave-to {
    transform: translateX(100%);

    [dir="rtl"] & {
      transform: translateX(-100%);
    }
  }

  &-enter-to,
  &-leave {
    transform: translateX(0);
  }
}
</style>
