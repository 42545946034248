import { getRegionalConfigVar } from "./getRegionalConfigVar";

export default {
  get STRIPE_PUBLISHABLE_KEY(): string {
    return getRegionalConfigVar("VUE_APP_STRIPE_PUBLISHABLE_KEY");
  },
  get STRIPE_SANDBOX_PUBLISH_KEY(): string {
    return getRegionalConfigVar("VUE_APP_STRIPE_SANDBOX_PUBLISH_KEY");
  },
  get HELLO_SIGN_CLIENT_ID(): string {
    return getRegionalConfigVar("VUE_APP_HELLO_SIGN_CLIENT_ID");
  },
  get AMPLITUDE_API_KEY(): string {
    return getRegionalConfigVar("VUE_APP_AMPLITUDE_API_KEY");
  },
  get SENTRY_DSN(): string {
    return getRegionalConfigVar("VUE_APP_SENTRY_DSN");
  },
  get STRIPE_PAYMENT_GATEWAY_UUID(): string {
    return getRegionalConfigVar("VUE_APP_STRIPE_PAYMENT_GATEWAY_UUID");
  },
  get STRIPE_SANDBOX_PAYMENT_GATEWAY_UUID(): string {
    return getRegionalConfigVar("VUE_APP_STRIPE_SANDBOX_PAYMENT_GATEWAY_UUID");
  },
  get TREMENDOUS_CLIENT_ID(): string {
    return getRegionalConfigVar("VUE_APP_TREMENDOUS_CLIENT_ID", "");
  },
  get DEMO_TREMENDOUS_CLIENT_ID(): string {
    return getRegionalConfigVar("VUE_APP_DEMO_TREMENDOUS_CLIENT_ID", "");
  },
  get METICULOUS_RECORDING_TOKEN(): string {
    return getRegionalConfigVar("VUE_APP_METICULOUS_RECORDING_TOKEN", "");
  },
};
