/** This file is loaded early in test setup
 * Minimize dependencies and try dynamic imports when possible to avoid side effects and allow mocking.
 */
import * as Sentry from "@sentry/browser";
import { usePreferredLanguages } from "@vueuse/core";
import dayjs from "dayjs";
import Vue, {
  computed,
  ComputedRef,
  getCurrentInstance,
  ref,
  watch,
  watchEffect,
} from "vue";
import VueI18n, { I18nOptions, IVueI18n, LocaleMessageObject } from "vue-i18n";
import { useRoute, useRouter } from "vue-router/composables";

import { useQueryParam } from "shared/helpers/useQueryParam";
import { Hotel } from "shared/schemas/hotels/Hotel";
import { HotelExternal } from "shared/schemas/hotels/HotelExternal";
import { I18nString } from "shared/schemas/I18nString";

import {
  DEFAULT_LANGUAGE,
  LanguageCode,
  languageNames,
  languages,
  RTL_LANGUAGES,
} from "../constants/languages";

export type LoadLanguageFn = (
  lang: LanguageCode,
) => Promise<LocaleMessageObject>;

export type LanguageUpdatedCallback = (lang: LanguageCode) => void;

Vue.use(VueI18n);

export class I18n {
  public vueI18n: VueI18n;
  // en language is loaded through initialize
  private loadedLanguages: LanguageCode[] = [];
  // This is set via `initialize`.
  private localeTypes: LocaleType[];
  private onLanguageUpdated: LanguageUpdatedCallback | null | undefined = null;
  // This is used to force a specific translation language for testing
  private forceTranslationLanguage: LanguageCode | null = null;

  constructor(options: I18nOptions = {}) {
    this.vueI18n = new VueI18n({
      locale: languages.EN,
      fallbackLocale: [languages.EN],
      ...options,
    });
    this.localeTypes = [];
  }

  /**
   * Initializes Vue i18n and returns the configured VueI18n instance.
   * @param {Function} obj.loadLanguage Function to load a specific locale.
   *    Should return the locale message object.
   */
  async initialize({
    localeTypes,
    onLanguageUpdated,
    forceTranslationLanguage = null,
  }: {
    defaultMessages?: LocaleMessageObject;
    localeTypes: Array<`${LocaleType}`>;
    onLanguageUpdated?: LanguageUpdatedCallback;
    forceTranslationLanguage?: LanguageCode | null;
  }): Promise<VueI18n> {
    // localeType is a string union from LocaleType
    this.localeTypes = localeTypes as LocaleType[];
    this.forceTranslationLanguage = forceTranslationLanguage;
    this.onLanguageUpdated = onLanguageUpdated;
    await this.loadLanguageAsync(DEFAULT_LANGUAGE);
    return this.vueI18n;
  }

  private setI18nLanguage(lang: LanguageCode) {
    this.vueI18n.locale = lang;
    document.dir = RTL_LANGUAGES.includes(lang) ? "rtl" : "";
    document.documentElement.setAttribute("lang", lang);
    if (this.onLanguageUpdated) {
      this.onLanguageUpdated(lang);
    }
    // eslint-disable-next-line no-console
    console.info(`Locale is set to ${lang}.`);
    return lang;
  }

  async loadLanguageAsync(locale: LanguageCode): Promise<LanguageCode> {
    const lang = this.forceTranslationLanguage ?? locale;

    // Load locale messages with dynamic import
    // keep daytime localized even if translation language is forced
    await _loadDayjsLocale(locale); // Preload dayjs locale

    // Set immediately if the language already has been loaded.
    if (this.loadedLanguages.includes(lang)) {
      return this.setI18nLanguage(lang);
    }

    const messages = (
      await Promise.all(
        this.localeTypes.map(type => _loadLocale({ type, locale: lang })),
      )
    ).reduce((acc, module) => {
      return { ...acc, ...module.default };
    }, {});

    // set locale and locale message
    this.vueI18n.setLocaleMessage(lang, messages);
    this.loadedLanguages.push(lang);

    return this.setI18nLanguage(lang);
  }
}

const i18nInstance = new I18n();
export default i18nInstance;

/**
 * Transform the language enum value into the expected bundle name.
 * Lokalise outputs filenames like `pt_BR.json` but the
 * language enums use dashes: `pr-BR`.
 */
export function getAppLocaleBundleName(language: string): string {
  return language.replace("-", "_");
}

type GetI18nStringType = (str?: I18nString | null) => string | null;

/**
 * composition API for vue-i18n
 * model after vue-i18n useI18n for Vue 3
 *
 * getI18nString: a function that takes an I18nString and returns the string in the current locale.  Will
 * fallback to english if the current locale is not available. returns null if no string is found.
 */
export function useI18n(): {
  i18n: VueI18n;
  getI18nString: ComputedRef<GetI18nStringType>;
} & Pick<IVueI18n, "t" | "tc" | "te" | "d" | "n"> {
  // TODO: getCurrentInstance is not documented and should only used in library
  // We should move this to vue-i18n `useI18n` when possible
  const vueI18n = getCurrentInstance()?.proxy.$i18n;
  if (!vueI18n) {
    throw new Error(
      "Either i18n is not initialized or `useI18n` is not called at top level of component.setup",
    );
  }

  const getI18nString = computed(
    (): GetI18nStringType => str =>
      str?.[vueI18n.locale as LanguageCode] ?? str?.[DEFAULT_LANGUAGE] ?? null,
  );

  return {
    i18n: vueI18n,
    getI18nString,
    t: vueI18n.t.bind(vueI18n),
    tc: vueI18n.tc.bind(vueI18n),
    te: vueI18n.te.bind(vueI18n),
    d: vueI18n.d.bind(vueI18n),
    n: vueI18n.n.bind(vueI18n),
  };
}

export function getCurrentLocale(
  defaultLocale: LanguageCode = "en",
): LanguageCode {
  try {
    return (useI18n().i18n.locale as LanguageCode) || defaultLocale;
  } catch (e) {
    Sentry.captureException(e);
    return defaultLocale;
  }
}

export async function _loadDayjsLocale(locale: LanguageCode): Promise<void> {
  // dayjs's en locale definition should not be activated
  // since it is incomplete and will cause error for built-in format(ll).
  // Also not needed since it is the default locale
  if (locale === "en") return;
  const { default: localeData } = await _loadLocale({
    type: LocaleType._DAYJS,
    locale,
    matchLocaleImport: (importPath, importLocale) => {
      // dayjs locale files are named `pt-br.js`
      const bundleName = importLocale.toLowerCase();
      return importPath.endsWith(`${bundleName}.js`);
    },
  });
  // Load locale data
  dayjs.locale(localeData, undefined, true);
  // Set current locale globally
  dayjs.locale(locale);
}

export enum LocaleType {
  GUEST = "guest",
  HOTELS = "hotels",

  ADMINLAND = "adminland",
  AUTHORIZATIONS = "authorizations",
  CHECKIN = "check-in",
  PAYMENT_GATEWAY = "payment-gateway",
  CANARY_UI = "canary-ui",
  SHARED = "shared",
  EVENTS = "events",
  KIOSK = "kiosk",
  // Mark as private. dayjs should be loaded through `loadDayjsLocale`
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _DAYJS = "_dayjs",
}

// We need a map since dynamic import needs to be statically analyzable
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LOCALE_MAP: Record<LocaleType, Record<string, () => Promise<any>>> = {
  hotels: import.meta.glob(`../../../hotels/src/locale/*.json`),
  adminland: import.meta.glob(`../../../adminland/src/locale/*.json`),
  authorizations: import.meta.glob(`../../../authorizations/src/locale/*.json`),
  "check-in": import.meta.glob(`../../../check-in/src/locale/*.json`),
  "payment-gateway": import.meta.glob(
    `../../payment-gateway/src/locale/*.json`,
  ),
  "canary-ui": import.meta.glob(`../../canary-ui/src/locale/*.json`),
  shared: import.meta.glob(`../../shared/locale/*.json`),
  events: import.meta.glob(`../../canary-ui/src/locale/events/*.json`),
  guest: import.meta.glob(`../../../guest/src/locale/*.json`),
  kiosk: import.meta.glob(`../../../kiosk/src/locale/*.json`),
  _dayjs:
    // dynamic import with vite/rollup needs to have relative/alias for node_modules
    import.meta.glob(`../../../node_modules/dayjs/esm/locale/*.js`),
} as const;

function matchAppLocaleImport(importPath: string, language: string): boolean {
  const bundleName = getAppLocaleBundleName(language);
  return importPath.endsWith(`${bundleName}.json`);
}

/**
 * A thin wrapper that add error handling to the app locale loading.
 */
export async function _loadLocale({
  type,
  locale,
  matchLocaleImport = matchAppLocaleImport,
}: {
  type: LocaleType;
  locale: LanguageCode;
  matchLocaleImport?: (importPath: string, locale: string) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> {
  for (const importPath of Object.keys(LOCALE_MAP[type] || [])) {
    if (matchLocaleImport(importPath, locale)) {
      return LOCALE_MAP[type][importPath]();
    }
  }
  Sentry.captureException(`Invalid ${type} locale '${locale}'`);
  return { default: {} };
}

/**
 * Given a list of supported languages, find the best match
 * Ex: en-US, ['en-US', 'en-GB'] => 'en-US'
 * Ex: en-US, ['en-GB'] => 'en'
 */
export function findSupportedLocale(
  requested: readonly string[],
  supported: readonly string[],
): LanguageCode {
  if (!supported.length) return DEFAULT_LANGUAGE;
  const canonicalizedToSupported = new Map(
    supported.map(lang => [getCanonicalLocale(lang), lang]),
  );
  const canonicalizedRequested = requested.map(getCanonicalLocale);
  const allRequested: string[] = [];
  for (let i = 0; i < canonicalizedRequested.length; i += 1) {
    allRequested.push(
      canonicalizedRequested[i],
      canonicalizedRequested[i].substring(0, 2),
    );
  }
  const firstLanguageMatch = allRequested.find(lang => {
    return canonicalizedToSupported.has(lang);
  });
  // @ts-expect-error: Since firstLanguageMatch must be a key of canonicalizedToSupported,
  // canonicalizedToSupported.get(firstLanguageMatch) should not be undefined
  return firstLanguageMatch
    ? canonicalizedToSupported.get(firstLanguageMatch)
    : DEFAULT_LANGUAGE;
}

function getCanonicalLocale(locale: string): string {
  return locale.replace(/_/g, "-").toLowerCase();
}

/**
 * Helper that returns either browser locale or the default locale
 * resolved against our supported locales.
 * This should not be used directly. Use useQueryParamLocale or useUserLocale instead.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const _useLocale = () => {
  const browserLocales = usePreferredLanguages();

  const selectedLocale = ref<string | null>(null);

  const locale = computed(() => {
    if (selectedLocale.value) {
      return findSupportedLocale(
        [selectedLocale.value],
        Object.values(languages),
      );
    }
    return findSupportedLocale(browserLocales.value, Object.values(languages));
  });

  watchEffect(() => {
    i18nInstance.loadLanguageAsync(locale.value);
  });

  return {
    selectLocale: (selected: string | null) => {
      selectedLocale.value = selected;
    },
    locale,
    browserLocale: computed(() => browserLocales.value[0]),
  };
};

/**
 * Return supported locale in this order: url query > browser > default
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQueryParamLocale = ({
  supportedLocales,
}: {
  supportedLocales?: LanguageCode[];
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
} = {}) => {
  const allLanguages = Object.values(languages);
  let filteredSupportedLocales = Object.values(languages);
  if (supportedLocales) {
    filteredSupportedLocales = supportedLocales.filter(lang => {
      return allLanguages.includes(lang);
    });
  }

  const queryParamLocale = useQueryParam<LanguageCode>("lang");
  const { selectLocale, locale, browserLocale } = _useLocale();

  // Remove invalid locale from query param
  watchEffect(() => {
    if (!filteredSupportedLocales.includes(locale.value)) {
      console.error(`Language ${queryParamLocale.value} is not supported.`);
      queryParamLocale.value = null;
    }
  });

  watchEffect(() => {
    selectLocale(queryParamLocale.value);
  });

  return {
    locale,
    rawBrowserLocale: browserLocale,
  };
};

/**
 * Return supported locale in this order: user > browser > default
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useHotelUserLocale = () => {
  const { selectLocale, locale, browserLocale } = _useLocale();
  const rawUserLocale = ref<string | null>(null);

  watchEffect(() => {
    selectLocale(rawUserLocale.value);
  });

  import("shared/api/hotels/OwnUser").then(
    ({ getCurrentUserPreferredLanguage }) => {
      getCurrentUserPreferredLanguage().then(({ preferred_language }) => {
        rawUserLocale.value = preferred_language || null;
      });
    },
  );

  return {
    rawBrowserLocale: browserLocale,
    rawUserLocale,
    locale,
  };
};

/**
 * Get supported languages for a hotel and set the locale based on the query param.
 *
 * @example
 * const { setLocale, languageOptions } = useHotelLanguages(hotel, allowedLanguages);
 * setLocale('en');
 * console.log(languageOptions.value); // { en: 'English', fr: 'French' }
 */
export const useHotelLanguages = (
  hotel: ComputedRef<Hotel | HotelExternal>,
  allowedLanguages: ComputedRef<LanguageCode[] | null>,
): {
  setLocale: (lang: LanguageCode) => void;
  locale: ComputedRef<LanguageCode>;
  languageOptions: ComputedRef<I18nString>;
} => {
  const router = useRouter();
  const route = useRoute();
  const { selectLocale, locale } = _useLocale();
  const { i18n } = useI18n();

  const supportedLanguages = computed(() => {
    const hotelLanguages = hotel.value.supported_languages ?? [languages.EN];
    const canaryLanguages = Object.values(languages);
    const supportedLangs = allowedLanguages.value
      ? hotelLanguages.filter(
          languageCode => allowedLanguages.value?.includes(languageCode),
        )
      : hotelLanguages;

    return supportedLangs
      .filter(lang => canaryLanguages.includes(lang))
      .reduce(
        (acc, val: LanguageCode) => {
          acc[val] = languageNames[val];
          return acc;
        },
        {} as Partial<Record<LanguageCode, string>>,
      );
  });

  watch(
    () => i18n.locale,
    (newValue: string, oldValue: string | null) => {
      if (newValue && newValue !== oldValue) {
        router.replace({ query: { ...route.query, lang: newValue } });
      }
      selectLocale(newValue as LanguageCode);
    },
  );
  watch(
    () => supportedLanguages.value,
    (newValue: Partial<Record<LanguageCode, string>>) => {
      if (Object.keys(newValue).length) {
        if (route.query.lang) {
          i18n.locale = route.query.lang as LanguageCode;
        }
      }
    },
    { immediate: true },
  );

  return {
    setLocale: selectLocale,
    locale,
    languageOptions: supportedLanguages,
  };
};
